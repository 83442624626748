import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Escucha radio en internet Gratis" />
        <section class="hero is-fullheight is-black">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <p class="title">
                        Mejor en la Radio
                    </p>
                    <p class="subtitle">
                        ¡Escucha radio en internet Gratis!
                    </p>

                <a href="https://docs.google.com/forms/d/e/1FAIpQLSejFRUNrIJ66W0byp9weA8R2UojzWJc6_PO8RSE5NAgUMEYLw/viewform" class="button is-link" id="add-station">Agregar radio</a>
    </div>
    </div>
    </section>
    </Layout>
)

export default IndexPage
